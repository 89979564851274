import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image"

import { defImg } from "./image.module.scss"

const imgQuery = graphql`
  {
    image: file(relativePath: { eq: "WaysToGetCard/cards.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export function DefImg(props: Omit<GatsbyImageProps, "className" | "image" | "objectFit">) {
  const data = useStaticQuery(imgQuery)

  return (
    <GatsbyImage {...props} className={defImg} image={getImage(data.image)!} objectFit="unset" />
  )
}
