import React from "react"

import { graphql, navigate, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import Banner from "../components/Banners/HalvaQr"
import Advantages from "../components/Advantages"
import Conditions from "../components/Conditions"
import WaysToGetCard from "../components/WaysToGetCard"
import Partners from "../components/Partners"
import BaseHeader from "../components/Headers/Main"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"

export const DEFAULT_ITEMS = [
  {
    sup2: "до",
    count: "10",
    sup: "%",
    text1: "кэшбэк",
    text2: "",
  },
  {
    sup2: "",
    count: "0",
    sup: "₽",
    text1: "оформление",
    text2: "и обслуживание",
  },
  {
    sup2: "",
    count: <>24</>,
    sup: "",
    text1: "месяца",
    text2: "рассрочки",
  },
]

const handleClick = () => {
  navigate("https://anketa.sovcombank.ru/")
}

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout ligal={ligal} phones={phones} noHeader noIndex={noIndex}>
      <BaseHeader btnClick={handleClick} />
      <Banner orderNum="1" />
      <Advantages items={DEFAULT_ITEMS} orderNum="2" />
      <Conditions rate="17" orderNum="3" />
      <WaysToGetCard wayToGet="Для карты Халва доступны:" orderNum="4" />
      <Partners orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-qr/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
