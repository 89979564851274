import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import getSearchParam from "@ecom/ui/utils/getSearchParam"
import { navigate } from "gatsby"

import { Img } from "./Img"

import * as styles from "./halvaQrBanner.module.scss"
import { DLClickEvent } from "../../../helpers/WatcherDL/DLEvents"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const secondStep =
  getSearchParam("utm_medium") === "POS" &&
  getSearchParam("utm_source") === "scboffice" &&
  getSearchParam("utm_campaign") === "sincere-service" &&
  getSearchParam("utm_content") === "qrcode-bankomat" &&
  getSearchParam("utm_term") === "fkts-mpp"
    ? "Заполните форму заявки"
    : "Перейдите на страницу с оформлением заявки"

const items = [
  <>Нажмите на кнопку «Оформить»</>,
  <>{secondStep}</>,
  <>
    После того, как получите карту, сделайте 1 покупку на любую сумму собственными или заёмными
    средствами!
  </>,
]

const buttonText = "Оформить"

type HalvaQrBannerProps = {
  orderNum: string
  scrollInstead?: boolean
}

export default function HalvaQrBanner({ orderNum, scrollInstead = false }: HalvaQrBannerProps) {
  const handleClick = scrollInstead
    ? () => handleClickBanner("click_cta")
    : () => {
        DLClickEvent({ name: "click_cta", placement: "banner" })
        navigate("https://anketa.sovcombank.ru/")
      }

  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Img className={styles.img} alt="bg image" />
      <Container className={styles.container}>
        <div className={styles.textBlock}>
          <h1>Оформите быструю заявку на карту «Халва»!</h1>
          <ul>
            {items.map((item, i) => (
              <li key={i}>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </div>
        <Button className={styles.btn} onClick={handleClick}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
