import React from "react"

import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Box from "@material-ui/core/Box"

import { DefImg } from "./Images/DefImg"

import icon_user from "./img/icon_user.svg"
import icon_delivery from "./img/icon_delivery.svg"

import * as styles from "./waystogetcard.module.scss"

const items = [
  { img: icon_delivery, txt: "Бесплатная доставка" },
  { img: icon_user, txt: "Cамовывоз" },
]

type WaysToGetCardProps = {
  Img?: typeof DefImg
  wayToGet?: string
  orderNum?: string
}

export default function WaysToGetCard({
  Img = DefImg,
  wayToGet = "Карта Халва доступна: ",
  orderNum,
}: WaysToGetCardProps) {
  return (
    <section className={clsx(styles.bg)} data-exclude={orderNum}>
      <Img alt="cards" />
      <Container className={styles.container}>
        <h2 className={clsx(styles.head, "text-left")}>{wayToGet}</h2>
        <ul className={styles.list}>
          {items.map(({ img, txt }, i) => (
            <Box
              display="flex"
              alignItems="center"
              component="li"
              key={i}
              className={styles.item}
              style={{ backgroundImage: `url(${img})` }}
            >
              {txt}
            </Box>
          ))}
        </ul>
      </Container>
    </section>
  )
}
