import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection, GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    mob: file(relativePath: { eq: "Banners/inviteFriend/mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 80)
      }
    }
    tab: file(relativePath: { eq: "Banners/inviteFriend/tab.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 80)
      }
    }
    desk: file(relativePath: { eq: "Banners/inviteFriend/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 80)
      }
    }
    large: file(relativePath: { eq: "Banners/inviteFriend/lg.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, quality: 80)
      }
    }
  }
`

export function Img(props: Omit<GatsbyImageProps, "image">) {
  const data = useStaticQuery(imgQuery)
  const images = withArtDirection(getImage(data.large)!, [
    {
      media: "(max-width: 599.95px)",
      image: getImage(data.mob)!,
    },
    {
      media: "(max-width: 959.95px)",
      image: getImage(data.tab)!,
    },
    {
      media: "(max-width: 1279.95px)",
      image: getImage(data.desk)!,
    },
  ])

  return <GatsbyImage {...props} image={images} />
}
